export const marketAnalysisPage = "/";
export const marketAnalysisDetail = "/market-analysis-details";
export const updateResult = "/update-result";
export const updateTime = "/update-time";
export const updateResultTime = "/update-result-time";
export const addGame = "/add-game";
export const withdrawal = "/withdrawal";
export const transaction = "/transaction";
export const paidMoney = "/paide-money";
export const signIn = "/sign-in";
export const LoginScreen = "/Login";
export const CreateAdminScreen = "/create-admin";
export const all_User = "/all-user";
export const view_Transaction = "/view-transaction/";
export const view_User_Game_Data = "/view-user-game-detail/";
export const add_content = "/add-content";
export const all_Handling = "/all-handling";
export const all_Sub_Admin = "/all-sub-admin";
export const all_UPI_Detail = "/all-Upi";
export const dashboard = "/dashboard";
export const rejectedRequest = "/rejected-request";
export const paymentAccount = "/paymentAccount";
export const Application_Banner = "/application-banner";

