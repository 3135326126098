export const ActionType = {
  REQUEST_PENDING_MARKET_ANALYSIS: "REQUEST_PENDING_MARKET_ANALYSIS",
  REQUEST_SUCCESS_MARKET_ANALYSIS: "REQUEST_SUCCESS_MARKET_ANALYSIS",
  REQUEST_ERROR_MARKET_ANALYSIS: "REQUEST_ERROR_MARKET_ANALYSIS",
  REQUEST_PENDING_GAME_DETAIL: "REQUEST_PENDING_GAME_DETAIL",
  REQUEST_SUCCESS_GAME_DETAIL: "REQUEST_SUCCESS_GAME_DETAIL",
  REQUEST_ERROR_GAME_DETAIL: "REQUEST_ERROR_GAME_DETAIL",
  REQUEST_PENDING_GET_USER_BY_BET: "REQUEST_PENDING_GET_USER_BY_BET",
  REQUEST_SUCCESS_GET_USER_BY_BET: "REQUEST_SUCCESS_GET_USER_BY_BET",
  REQUEST_ERROR_GET_USER_BY_BET: "REQUEST_ERROR_GET_USER_BY_BET",
  REQUEST_ERROR_UPDATE_RESULT: "REQUEST_ERROR_UPDATE_RESULT",
  REQUEST_SUCCESS_UPDATE_RESULT: "REQUEST_SUCCESS_UPDATE_RESULT",
  REQUEST_PENDING_UPDATE_RESULT: "REQUEST_PENDING_UPDATE_RESULT",
  REQUEST_PENDING_UPDATE_TIME: "REQUEST_PENDING_UPDATE_TIME",
  REQUEST_SUCCESS_UPDATE_TIME: "REQUEST_SUCCESS_UPDATE_TIME",
  REQUEST_ERROR_UPDATE_TIME: "REQUEST_ERROR_UPDATE_TIME",
  REQUEST_ERROR_UPDATE_RESULT_TIME: "REQUEST_ERROR_UPDATE_RESULT_TIME",
  REQUEST_SUCCESS_UPDATE_RESULT_TIME: "REQUEST_SUCCESS_UPDATE_RESULT_TIME",
  REQUEST_PENDING_UPDATE_RESULT_TIME: "REQUEST_PENDING_UPDATE_RESULT_TIME",
  REQUEST_PENDING_CREATE_GAME: "REQUEST_PENDING_CREATE_GAME",
  REQUEST_SUCCESS_CREATE_GAME: "REQUEST_SUCCESS_CREATE_GAME",
  REQUEST_ERROR_CREATE_GAME: "REQUEST_ERROR_CREATE_GAME",
  REQUEST_ERROR_MONTH_RESULT: "REQUEST_ERROR_MONTH_RESULT",
  REQUEST_PENDING_MONTH_RESULT: "REQUEST_PENDING_MONTH_RESULT",
  REQUEST_SUCCESS_MONTH_RESULT: "REQUEST_SUCCESS_MONTH_RESULT",
  REQUEST_PENDING_MONTH_DATA: "REQUEST_PENDING_MONTH_DATA",
  REQUEST_SUCCESS_MONTH_DATA: "REQUEST_SUCCESS_MONTH_DATA",
  REQUEST_ERROR_MONTH_DATA: "REQUEST_ERROR_MONTH_DATA",
  REQUEST_ERROR_WITHDRAWAL: "REQUEST_ERROR_WITHDRAWAL",
  REQUEST_PENDING_WITHDRAWAL: "REQUEST_PENDING_WITHDRAWAL",
  REQUEST_SUCCESS_WITHDRAWAL: "REQUEST_SUCCESS_WITHDRAWAL",
  REQUEST_SUCCESS_APPROVE_WITHDRAWAL_REQUEST:
    "REQUEST_SUCCESS_APPROVE_WITHDRAWAL_REQUEST",
  REQUEST_ERROR_APPROVE_WITHDRAWAL_REQUEST:
    "REQUEST_ERROR_APPROVE_WITHDRAWAL_REQUEST",
  REQUEST_PENDING_APPROVE_WITHDRAWAL_REQUEST:
    "REQUEST_PENDING_APPROVE_WITHDRAWAL_REQUEST",
  // REQUEST_PENDING_REC:
  // "REQUEST_PENDING_APPROVE_WITHDRAWAL_REQUEST",
  REQUEST_SUCCESS_WITHDRAWAL_REQUEST: "REQUEST_SUCCESS_WITHDRAWAL_REQUEST",
  REQUEST_PENDING_WITHDRAWAL_REQUEST: "REQUEST_PENDING_WITHDRAWAL_REQUEST",
  REQUEST_ERROR_WITHDRAWAL_REQUEST: "REQUEST_ERROR_WITHDRAWAL_REQUEST",

  REQUEST_PENDING_GET_RECHARGE_AMOUNT: "REQUEST_PENDING_GET_RECHARGE_AMOUNT",
  REQUEST_SUCCESS_GET_RECHARGE_AMOUNT: "REQUEST_SUCCESS_GET_RECHARGE_AMOUNT",
  REQUEST_ERROR_GET_RECHARGE_AMOUNT: "REQUEST_ERROR_GET_RECHARGE_AMOUNT",

  REQUEST_ERROR_PAID_MONEY: "REQUEST_ERROR_PAID_MONEY",
  REQUEST_SUCCESS_PAID_MONEY: "REQUEST_SUCCESS_PAID_MONEY",
  REQUEST_PENDING_PAID_MONEY: "REQUEST_PENDING_PAID_MONEY",
  REQUEST_PENDING_ADD_ACCOUNT_DETAIL: "REQUEST_PENDING_ADD_ACCOUNT_DETAIL",
  REQUEST_SUCCESS_ADD_ACCOUNT_DETAIL: "REQUEST_SUCCESS_ADD_ACCOUNT_DETAIL",
  REQUEST_ERROR_ADD_ACCOUNT_DETAIL: "REQUEST_ERROR_ADD_ACCOUNT_DETAIL",
  REQUEST_ERROR_GET_NUMBER_DETAIL: "REQUEST_ERROR_GET_NUMBER_DETAIL",
  REQUEST_PENDING_GET_NUMBER_DETAIL: "REQUEST_PENDING_GET_NUMBER_DETAIL",
  REQUEST_SUCCESS_GET_NUMBER_DETAIL: "REQUEST_SUCCESS_GET_NUMBER_DETAIL",
  REQUEST_PENDING_SIGN_IN: "REQUEST_PENDING_SIGN_IN",
  REQUEST_SUCCESS_SIGN_IN: "REQUEST_SUCCESS_SIGN_IN",
  REQUEST_ERROR_SIGN_IN: "REQUEST_ERROR_SIGN_IN",
  REQUEST_ERROR_OTP_VERIFIED: "REQUEST_ERROR_OTP_VERIFIED",
  REQUEST_PENDING_OTP_VERIFIED: "REQUEST_PENDING_OTP_VERIFIED",
  REQUEST_SUCCESS_OTP_VERIFIED: "REQUEST_SUCCESS_OTP_VERIFIED",
  REQUEST_SUCCESS_LOGOUT: "REQUEST_SUCCESS_LOGOUT",
  REQUEST_PENDING_LOGOUT: "REQUEST_PENDING_LOGOUT",
  REQUEST_ERROR_LOGOUT: "REQUEST_ERROR_LOGOUT",
  REQUEST_ERROR_CREATE_ADMIN: "REQUEST_ERROR_CREATE_ADMIN",
  REQUEST_PENDING_CREATE_ADMIN: "REQUEST_PENDING_CREATE_ADMIN",
  REQUEST_SUCCESS_CREATE_ADMIN: "REQUEST_SUCCESS_CREATE_ADMIN",
  REQUEST_SUCCESS_UPDATE_APPLICATION: "REQUEST_SUCCESS_UPDATE_APPLICATION",
  REQUEST_ERROR_UPDATE_APPLICATION: "REQUEST_ERROR_UPDATE_APPLICATION",
  REQUEST_PENDING_UPDATE_APPLICATION: "REQUEST_PENDING_UPDATE_APPLICATION",
  REQUEST_PENDING_ALL_USER_DETAIL: "REQUEST_PENDING_ALL_USER_DETAIL",
  REQUEST_SUCCESS_ALL_USER_DETAIL: "REQUEST_SUCCESS_ALL_USER_DETAIL",
  REQUEST_ERROR_ALL_USER_DETAIL: "REQUEST_ERROR_ALL_USER_DETAIL",
  REQUEST_ERROR_USER_TRANSACTION: "REQUEST_ERROR_USER_TRANSACTION",
  REQUEST_PENDING_USER_TRANSACTION: "REQUEST_PENDING_USER_TRANSACTION",
  REQUEST_SUCCESS_USER_TRANSACTION: "REQUEST_SUCCESS_USER_TRANSACTION",
  REQUEST_SUCCESS_USER_GAME_DATA: "REQUEST_SUCCESS_USER_GAME_DATA",
  REQUEST_PENDING_USER_GAME_DATA: "REQUEST_PENDING_USER_GAME_DATA",
  REQUEST_ERROR_USER_GAME_DATA: "REQUEST_ERROR_USER_GAME_DATA",
  REQUEST_ERROR_ADD_CONTENT: "REQUEST_ERROR_ADD_CONTENT",
  REQUEST_SUCCESS_ADD_CONTENT: "REQUEST_SUCCESS_ADD_CONTENT",
  REQUEST_PENDING_ADD_CONTENT: "REQUEST_PENDING_ADD_CONTENT",
  REQUEST_PENDING_UPDATE_WITHDRAWAL_TIMING:
    "REQUEST_PENDING_UPDATE_WITHDRAWAL_TIMING",
  REQUEST_SUCCESS_UPDATE_WITHDRAWAL_TIMING:
    "REQUEST_SUCCESS_UPDATE_WITHDRAWAL_TIMING",
  REQUEST_ERROR_UPDATE_WITHDRAWAL_TIMING:
    "REQUEST_ERROR_UPDATE_WITHDRAWAL_TIMING",
  REQUEST_ERROR_CALCULATION: "REQUEST_ERROR_CALCULATION",
  REQUEST_PENDING_CALCULATION: "REQUEST_PENDING_CALCULATION",
  REQUEST_SUCCESS_CALCULATION: "REQUEST_SUCCESS_CALCULATION",
  REQUEST_SUCCESS_GET_GAME_STATUS: "REQUEST_SUCCESS_GET_GAME_STATUS",
  REQUEST_ERROR_GET_GAME_STATUS: "REQUEST_ERROR_GET_GAME_STATUS",
  REQUEST_PENDING_GET_GAME_STATUS: "REQUEST_PENDING_GET_GAME_STATUS",
  REQUEST_PENDING_APP_DETAIL: "REQUEST_PENDING_APP_DETAIL",
  REQUEST_ERROR_APP_DETAIL: "REQUEST_ERROR_APP_DETAIL",
  REQUEST_SUCCESS_APP_DETAIL: "REQUEST_SUCCESS_APP_DETAIL",
  REQUEST_SUCCESS_MENUPULATE: "REQUEST_SUCCESS_MENUPULATE",
  REQUEST_ERROR_MENUPULATE: "REQUEST_ERROR_MENUPULATE",
  REQUEST_PENDING_MENUPULATE: "REQUEST_PENDING_MENUPULATE",
  REQUEST_PENDING_ADD_HANDLING: "REQUEST_PENDING_ADD_HANDLING",
  REQUEST_SUCCESS_ADD_HANDLING: "REQUEST_SUCCESS_ADD_HANDLING",
  REQUEST_ERROR_ADD_HANDLING: "REQUEST_ERROR_ADD_HANDLING",
  REQUEST_ERROR_ALL_HANDLING: "REQUEST_ERROR_ALL_HANDLING",
  REQUEST_PENDING_ALL_HANDLING: "REQUEST_PENDING_ALL_HANDLING",
  REQUEST_SUCCESS_ALL_HANDLING: "REQUEST_SUCCESS_ALL_HANDLING",
  REQUEST_SUCCESS_DELETE_HANDLING: "REQUEST_SUCCESS_DELETE_HANDLING",
  REQUEST_PENDING_DELETE_HANDLING: "REQUEST_PENDING_DELETE_HANDLING",
  REQUEST_ERROR_DELETE_HANDLING: "REQUEST_ERROR_DELETE_HANDLING",
  REQUEST_ERROR_SUB_ADMIN_LIST: "REQUEST_ERROR_SUB_ADMIN_LIST",
  REQUEST_PENDING_SUB_ADMIN_LIST: "REQUEST_PENDING_SUB_ADMIN_LIST",
  REQUEST_SUCCESS_SUB_ADMIN_LIST: "REQUEST_SUCCESS_SUB_ADMIN_LIST",
  REQUEST_SUCCESS_BLOCK_ADMIN: "REQUEST_SUCCESS_BLOCK_ADMIN",
  REQUEST_PENDING_BLOCK_ADMIN: "REQUEST_PENDING_BLOCK_ADMIN",
  REQUEST_ERROR_BLOCK_ADMIN: "REQUEST_ERROR_BLOCK_ADMIN",
  REQUEST_ERROR_EDIT_PROFILE: "REQUEST_ERROR_EDIT_PROFILE",
  REQUEST_SUCCESS_EDIT_PROFILE: "REQUEST_SUCCESS_EDIT_PROFILE",
  REQUEST_PENDING_EDIT_PROFILE: "REQUEST_PENDING_EDIT_PROFILE",
  REQUEST_PENDING_EDIT_PROFILE_OTP: "REQUEST_PENDING_EDIT_PROFILE_OTP",
  REQUEST_ERROR_EDIT_PROFILE_OTP: "REQUEST_ERROR_EDIT_PROFILE_OTP",
  REQUEST_SUCCESS_EDIT_PROFILE_OTP: "REQUEST_SUCCESS_EDIT_PROFILE_OTP",
  REQUEST_PENDING_EDIT_PROFILE_PASSWORD:
    "REQUEST_PENDING_EDIT_PROFILE_PASSWORD",
  REQUEST_ERROR_EDIT_PROFILE_PASSWORD: "REQUEST_PENDING_EDIT_PROFILE_PASSWORD",
  REQUEST_SUCCESS_EDIT_PROFILE_PASSWORD:
    "REQUEST_SUCCESS_EDIT_PROFILE_PASSWORD",
  REQUEST_SUCCESS_ALL_UPI_LIST: "REQUEST_SUCCESS_ALL_UPI_LIST",
  REQUEST_ERROR_ALL_UPI_LIST: "REQUEST_ERROR_ALL_UPI_LIST",
  REQUEST_PENDING_ALL_UPI_LIST: "REQUEST_PENDING_ALL_UPI_LIST",
  REQUEST_PENDING_UPDATE_UPI_STATUS: "REQUEST_PENDING_UPDATE_UPI_STATUS",
  REQUEST_ERROR_UPDATE_UPI_STATUS: "REQUEST_ERROR_UPDATE_UPI_STATUS",
  REQUEST_SUCCESS_UPDATE_UPI_STATUS: "REQUEST_SUCCESS_UPDATE_UPI_STATUS",
  REQUEST_SUCCESS_PARTNER_USER: "REQUEST_SUCCESS_PARTNER_USER",
  REQUEST_ERROR_PARTNER_USER: "REQUEST_ERROR_PARTNER_USER",
  REQUEST_PENDING_PARTNER_USER: "REQUEST_PENDING_PARTNER_USER",
  REQUEST_PENDING_DASBOARD: "REQUEST_PENDING_DASBOARD",
  REQUEST_ERROR_DASBOARD: "REQUEST_ERROR_DASBOARD",
  REQUEST_SUCCESS_DASBOARD: "REQUEST_SUCCESS_DASBOARD",
  REQUEST_SUCCESS_USER_DETAIL: "REQUEST_SUCCESS_USER_DETAIL",
  REQUEST_PENDING_USER_DETAIL: "REQUEST_PENDING_USER_DETAIL",
  REQUEST_ERROR_USER_DETAIL: "REQUEST_ERROR_USER_DETAIL",
  REQUEST_ERROR_WINNING_AMOUNT: "REQUEST_ERROR_WINNING_AMOUNT",
  REQUEST_PENDING_WINNING_AMOUNT: "REQUEST_PENDING_WINNING_AMOUNT",
  REQUEST_SUCCESS_WINNING_AMOUNT: "REQUEST_SUCCESS_WINNING_AMOUNT",
  REQUEST_SUCCESS_NEW_USER: "REQUEST_SUCCESS_NEW_USER",
  REQUEST_PENDING_NEW_USER: "REQUEST_PENDING_NEW_USER",
  REQUEST_ERROR_NEW_USER: "REQUEST_ERROR_NEW_USER",
  REQUEST_ERROR_WINNING_RUPESS: "REQUEST_ERROR_WINNING_RUPESS",
  REQUEST_PENDING_WINNING_RUPESS: "REQUEST_PENDING_WINNING_RUPESS",
  REQUEST_SUCCESS_WINNING_RUPESS: "REQUEST_SUCCESS_WINNING_RUPESS",
  REQUEST_SUCCESS_USER_PROFILE: "REQUEST_SUCCESS_USER_PROFILE",
  REQUEST_ERROR_USER_PROFILE: "REQUEST_ERROR_USER_PROFILE",
  REQUEST_PENDING_USER_PROFILE: "REQUEST_PENDING_USER_PROFILE",
  REQUEST_PENDING_GAME_PLAYED_HISTORY: "REQUEST_PENDING_GAME_PLAYED_HISTORY",
  REQUEST_ERROR_GAME_PLAYED_HISTORY: "REQUEST_ERROR_GAME_PLAYED_HISTORY",
  REQUEST_SUCCESS_GAME_PLAYED_HISTORY: "REQUEST_SUCCESS_GAME_PLAYED_HISTORY",
  REQUEST_SUCCESS_USER_WINNING_HISTORY: "REQUEST_SUCCESS_USER_WINNING_HISTORY",
  REQUEST_PENDING_USER_WINNING_HISTORY: "REQUEST_PENDING_USER_WINNING_HISTORY",
  REQUEST_ERROR_USER_WINNING_HISTORY: "REQUEST_ERROR_USER_WINNING_HISTORY",
  REQUEST_ERROR_REJECTED_REQUEST: "REQUEST_ERROR_REJECTED_REQUEST",
  REQUEST_PENDING_REJECTED_REQUEST: "REQUEST_PENDING_REJECTED_REQUEST",
  REQUEST_SUCCESS_REJECTED_REQUEST: "REQUEST_SUCCESS_REJECTED_REQUEST",
  REQUEST_SUCCESS_ADMIN_ACCOUNTS: "REQUEST_SUCCESS_ADMIN_ACCOUNTS",
  REQUEST_PENDING_ADMIN_ACCOUNTS: "REQUEST_PENDING_ADMIN_ACCOUNTS",
  REQUEST_ERROR_ADMIN_ACCOUNTS: "REQUEST_ERROR_ADMIN_ACCOUNTS",
  REQUEST_ERROR_APPROVED_WITHDRAWAL: "REQUEST_ERROR_APPROVED_WITHDRAWAL",
  REQUEST_PENDING_APPROVED_WITHDRAWAL: "REQUEST_PENDING_APPROVED_WITHDRAWAL",
  REQUEST_SUCCESS_APPROVED_WITHDRAWAL: "REQUEST_SUCCESS_APPROVED_WITHDRAWAL",
  REQUEST_SUCCESS_FOR_WITHDRAWAL: "REQUEST_SUCCESS_FOR_WITHDRAWAL",
  REQUEST_PENDING_FOR_WITHDRAWAL: "REQUEST_PENDING_FOR_WITHDRAWAL",
  REQUEST_ERROR_FOR_WITHDRAWAL: "REQUEST_ERROR_FOR_WITHDRAWAL",
  REQUEST_ERROR_ALL_WALLET_TRANSACTION: "REQUEST_ERROR_ALL_WALLET_TRANSACTION",
  REQUEST_PENDING_ALL_WALLET_TRANSACTION:
    "REQUEST_PENDING_ALL_WALLET_TRANSACTION",
  REQUEST_SUCCESS_ALL_WALLET_TRANSACTION:
    "REQUEST_SUCCESS_ALL_WALLET_TRANSACTION",

  REQUEST_ERROR_PAYMENT_ACCOUNT_STATUS_CHANGE:
    "REQUEST_ERROR_PAYMENT_ACCOUNT_STATUS_CHANGE",
  REQUEST_PENDING_PAYMENT_ACCOUNT_STATUS_CHANGE:
    "REQUEST_PENDING_PAYMENT_ACCOUNT_STATUS_CHANGE",
  REQUEST_SUCCESS_PAYMENT_ACCOUNT_STATUS_CHANGE:
    "REQUEST_SUCCESS_PAYMENT_ACCOUNT_STATUS_CHANGE",
  REQUEST_SUCCESS_ADD_BANK_ACCOUNT_DETAIL:
    "REQUEST_SUCCESS_ADD_BANK_ACCOUNT_DETAIL",
  REQUEST_PENDING_ADD_BANK_ACCOUNT_DETAIL:
    "REQUEST_PENDING_ADD_BANK_ACCOUNT_DETAIL",
  REQUEST_ERROR_ADD_BANK_ACCOUNT_DETAIL:
    "REQUEST_ERROR_ADD_BANK_ACCOUNT_DETAIL",
  REQUEST_ERROR_GAME_HISTORY: "REQUEST_ERROR_GAME_HISTORY",
  REQUEST_SUCCESS_GAME_HISTORY: "REQUEST_SUCCESS_GAME_HISTORY",
  REQUEST_PENDING_GAME_HISTORY: "REQUEST_PENDING_GAME_HISTORY",
  REQUEST_PENDING_RECHARGE_CAPING: "REQUEST_PENDING_RECHARGE_CAPING",
  REQUEST_ERROR_RECHARGE_CAPING: "REQUEST_ERROR_RECHARGE_CAPING",
  REQUEST_SUCCESS_RECHARGE_CAPING: "REQUEST_SUCCESS_RECHARGE_CAPING",
  REQUEST_SUCCESS_ALL_TYPE_USER_AMOUNT: "REQUEST_SUCCESS_ALL_TYPE_USER_AMOUNT",
  REQUEST_PENDING_ALL_TYPE_USER_AMOUNT: "REQUEST_PENDING_ALL_TYPE_USER_AMOUNT",
  REQUEST_ERROR_ALL_TYPE_USER_AMOUNT: "REQUEST_ERROR_ALL_TYPE_USER_AMOUNT",
  REQUEST_ERROR_UPDATE_NUMBER: "REQUEST_ERROR_UPDATE_NUMBER",
  REQUEST_SUCCESS_UPDATE_NUMBER: "REQUEST_SUCCESS_UPDATE_NUMBER",
  REQUEST_PENDING_UPDATE_NUMBER: "REQUEST_PENDING_UPDATE_NUMBER",
  REQUEST_PENDING_SCROLLING_TEXT: "REQUEST_PENDING_SCROLLING_TEXT",
  REQUEST_SUCCESS_SCROLLING_TEXT: "REQUEST_SUCCESS_SCROLLING_TEXT",
  REQUEST_ERROR_SCROLLING_TEXT: "REQUEST_ERROR_SCROLLING_TEXT",
  REQUEST_ERROR_GET_BANNER: "REQUEST_ERROR_GET_BANNER",
  REQUEST_PENDING_GET_BANNER: "REQUEST_PENDING_GET_BANNER",
  REQUEST_SUCCESS_GET_BANNER: "REQUEST_SUCCESS_GET_BANNER",
  REQUEST_SUCCESS_ADD_BANNER: "REQUEST_SUCCESS_ADD_BANNER",
  REQUEST_PENDING_ADD_BANNER: "REQUEST_PENDING_ADD_BANNER",
  REQUEST_ERROR_ADD_BANNER: "REQUEST_ERROR_ADD_BANNER",
};
