import { Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const ReferHistory = ({ data }) => {
  const dataSource = data?.map((curElm, index) => {
    return {
      key: curElm.time + curElm.date + curElm.receiver,
      sno: index + 1,
      Date: curElm?.date,
      Time: curElm.time,
      Receiver: (
        <Link
          to={`/user-deatil/${curElm?.receiver}`}
          style={{ color: "black" }}
        >
          {curElm?.receiver}
        </Link>
      ),
      rewar: (
        <span className="blink" style={{ color: "green" }}>
          +100
        </span>
      ),
    };
  });

  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Time",
      dataIndex: "Time",
      key: "Time",
    },
    {
      title: "Refer To",
      dataIndex: "Receiver",
      key: "Receiver",
    },
    {
      title: "",
      dataIndex: "rewar",
      key: "rewar",
    },
  ];
  return (
    <div>
      <h2>Refer History</h2>
      <Table
        dataSource={dataSource}
        columns={columns}
        // style={{ width: "100%" }}
      />
    </div>
  );
};

export default ReferHistory;
