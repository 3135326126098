import { ActionType } from "../../actionType/ActionType";

const INITIAL_STATE = {
  data: null,
  error: false,
  isLoading: false,
};
export const allWalletTransactionReducer = (state = INITIAL_STATE, action) => {
  if (action?.type) {
    switch (action.type) {
      case ActionType.REQUEST_PENDING_ALL_WALLET_TRANSACTION:
        return {
          ...state,
          isLoading: true,
          data: null,
          message: false,
        };
      case ActionType.REQUEST_SUCCESS_ALL_WALLET_TRANSACTION:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
          message: true,
        };
      default:
        return state;
    }
  }
};
