import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allTypeUserWalletAmount } from "../../redux/action/allTypeUserWallet";
import { allTypeUserAmountTodos } from "../../redux/selector/Selector";
import { Table } from "antd";
import { BiRupee } from "react-icons/bi";

const WalletDataTable = ({ status, user }) => {
  // allTypeUserWalletAmount
  const payloadData = {
    status,
    id: user,
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allTypeUserWalletAmount(payloadData));
  }, [status]);

  const allTypeUserAmount = useSelector(allTypeUserAmountTodos);
  const data = allTypeUserAmount?.data?.data;
  const dataSource = data?.map((curElm, index) => {
    return {
      key: index + curElm?.date,
      sno: index + 1,
      date: curElm?.date,
      Particauler: curElm?.Particauler,
      Amount:
        curElm?.Status == 1 ? (
          <span
            style={{ color: "green", display: "flex", alignItems: "center" }}
          >
            +{curElm?.Amount} <BiRupee />
          </span>
        ) : (
          <span style={{ color: "red", display: "flex", alignItems: "center" }}>
            -{curElm?.Amount} <BiRupee />
          </span>
        ),
    };
  });

  //   Amount
  // :
  // "500.0"
  // Particauler
  // :
  // "Recharge Wallet"
  // Status
  // :
  // "1"
  // date
  // :
  // "14/07/2023"
  const columns = [
    {
      title: "sno",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Particauler",
      dataIndex: "Particauler",
      key: "Particauler",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
  ];

  return (
    <div>
      <h4>All Type User Amount</h4>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
};

export default WalletDataTable;
