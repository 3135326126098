import { ActionType } from "../../actionType/ActionType";

const INITIAL_STATE = {
  data: null,
  error: false,
  isLoading: false,
};
export const adminAccountsReducer = (state = INITIAL_STATE, action) => {
  if (action?.type) {
    switch (action.type) {
      case ActionType.REQUEST_PENDING_APPROVED_WITHDRAWAL:
        return {
          ...state,
          isLoading: true,
          data: null,
          message: false,
        };
      case ActionType.REQUEST_SUCCESS_APPROVED_WITHDRAWAL:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
          message: true,
        };
      default:
        return state;
    }
  }
};
