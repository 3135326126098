import { DatePicker, Input, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRechargeAmountTodos } from "../../redux/selector/Selector";
import { GetRechargeAmountAction } from "../../redux/action/getReachargeAmountAction/GetRechargeAmountAction";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../pagination/Pagination";
import dayjs from "dayjs";

const GetRechargeAmount = () => {
  const [serchvalue, setSerchvalue] = useState("");
  const [date, setDate] = useState(dayjs());
  const [fromdate, setFromdate] = useState(dayjs());
  const [range, setRange] = useState(50);
  const [payload, setPayload] = useState({
    index: 1,
    totalPages: 0,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      GetRechargeAmountAction({
        page: payload.index,
        date: moment(date).format("MM/DD/YYYY"),
        fromdate: moment(fromdate).format("MM/DD/YYYY"),
        range: range,
        serchvalue: serchvalue,
      })
    );
  }, [payload.index, date, fromdate, range, serchvalue]);

  const todos = useSelector(getRechargeAmountTodos);
  const data = todos?.data?.data;
  useEffect(() => {
    setPayload((prev) => {
      return {
        ...prev,
        totalPages: todos?.data?.Totoal_Pages,
      };
    });
  }, [todos?.data?.Totoal_Pages]);

  const dataSource = data?.map((res, index) => {
    return {
      sno: index + 1,
      date: res.date,
      time: res.time,
      name: (
        <Link to={`/user-deatil/${res.user}`} style={{ color: "black" }}>
          {res.name}
        </Link>
      ),
      user: (
        <Link to={`/user-deatil/${res.user}`} style={{ color: "black" }}>
          {res.user}
        </Link>
      ),
      Amount: res.Amount,
    };
  });

  const columns = [
    {
      title: "sno",
      dataIndex: "sno",
      key: "sno",
    },

    {
      title: "date",
      dataIndex: "date",
      key: "date",
      // filteredValue: [serchvalue],
      // onFilter: (value, record) => {
      //   return (
      //     String(record?.name?.props?.children)
      //       .toLowerCase()
      //       .includes(value.toLowerCase()) ||
      //     String(record?.date).toLowerCase().includes(value.toLowerCase()) ||
      //     String(record?.user?.props?.children)
      //       .toLowerCase()
      //       .includes(value.toLowerCase())
      //   );
      // },
    },
    {
      title: "time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "User Number",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
  ];

  const searchHandleChange = (e) => {
    setSerchvalue(e.target.value);
  };
  const onChange = (date, dateString) => {
    setDate(dateString);
  };
  const onChange2 = (date, dateString) => {
    setFromdate(dateString);
  };
  const handleChange = (value) => {
    setRange(value);
  };
  return (
    <div>
      <div
        className="search-all-user"
        style={{
          margin: "10px",
          display: "flex",
          alignItems: "end",
          gap: "10px",
        }}
      >
        <div>
          <h3>Search User</h3>
          <Input
            placeholder="search...."
            onChange={searchHandleChange}
            style={{ width: "200px" }}
            value={serchvalue}
            type="number"
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <label>From</label>
          <DatePicker onChange={onChange2} />
          <label>To</label>
          <DatePicker onChange={onChange} />
          <label>Select Range</label>{" "}
          <Select
            defaultValue="50"
            style={{
              width: 120,
            }}
            onChange={handleChange}
            // allowClear
            options={[
              {
                value: "50",
                label: "50",
              },
              {
                value: "100",
                label: "100",
              },
              {
                value: "300",
                label: "300",
              },
              {
                value: "400",
                label: "400",
              },
              {
                value: "500",
                label: "500",
              },
            ]}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        summary={(pageData) => {
          let totalBorrow = 0;

          pageData.forEach(({ Amount }) => {
            totalBorrow += Number(Amount);
          });
          return (
            <>
              <Table.Summary.Row style={{ background: "rgb(211, 211, 211)" }}>
                <Table.Summary.Cell index={0}>
                  Total Received
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>

                <Table.Summary.Cell index={3}>{totalBorrow}</Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <Pagination paginationData={payload} setPaginationData={setPayload} />
    </div>
  );
};

export default GetRechargeAmount;
