import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UploadBannerTodos,
  getBannerTodos,
} from "../../redux/selector/Selector";
import { getBanner } from "../../redux/action/getBanner";
import { Button, Card, Modal } from "antd";
import UplaodImage from "../../componets/uplaodImage/UplaodImage";
import { UplaodBannerAction } from "../../redux/action/uploadBanner/Uploadbanner";
const ApplicationBanner = () => {
  const [fileList2, setFileList2] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBanner());
  }, []);

  const data = useSelector(getBannerTodos);
  const bannerdata = data?.gameDeatilData?.data?.data;
  const handleClick = () => {
    let formData = new FormData();
    formData.append("ban", fileList2[0]?.originFileObj);
    formData.append("status", 1);

    dispatch(UplaodBannerAction(formData));
  };
  const [isModal, setIsModal] = useState(false);
  const [addBanner, setAddBanner] = useState({});
  const showModal = (userBanner) => {
    setAddBanner(userBanner);
    setIsModal(true);
  };
  const handleOk = () => {
    dispatch(UplaodBannerAction(addBanner));
    setIsModal(false);
  };
  const handleCancel = () => {
    setIsModal(false);
  };

  const bannerAction = useSelector(UploadBannerTodos);
  useEffect(() => {
    if (bannerAction?.data?.status == 200) {
      dispatch(getBanner());
      setFileList2([]);
    }
  }, [bannerAction]);

  return (
    <>
      <Modal
        open={isModal}
        onOk={handleOk}
        onCancel={handleCancel}
        title="Delete Banner"
      >
        Are You Sure...
      </Modal>
      <div
        style={{
          fontSize: "13px",
          background: "orange",
          marginBlock: "10px",
          paddingBlock: "1px",
          color: "white",
          paddingInline: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Application Banner</h2>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button style={{ height: "auto" }}>
            <UplaodImage setFileList2={setFileList2} fileList2={fileList2} />
          </Button>
          <Button onClick={handleClick}>Submit</Button>
        </div>
      </div>
      <div
        className="banner-card"
        style={{
          padding: "10px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        {bannerdata?.map((res) => {
          return (
            <Card
              hoverable
              style={{
                width: 220,
                height: 250,
                overflow: "hidden",
                position: "relative",
              }}
              cover={
                <img
                  height="100%"
                  width="100%"
                  alt="example"
                  src={`https://exchgalaxy.com/Game/ResultBackgroundImage/${res?.bannerimg}`}
                />
              }
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#6200EE",
                  width: "100%",
                  left: 0,
                  padding: "4px",
                }}
              >
                <Button
                  style={{
                    background: "orange",
                    color: "white",
                    border: "none",
                  }}
                  onClick={() => showModal({ status: 2, id: res?.id })}
                >
                  Delete
                </Button>
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
};

export default ApplicationBanner;
