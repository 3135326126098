import { Button, Input, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { scrollingText } from "../../redux/action/scrollingText";

const ScrollingText = () => {
  const [txt, setTxt] = useState("");
  const dispatch = useDispatch();
  const submit = () => {
    dispatch(scrollingText({ txt }));
  };
  return (
    <div>
      <h3>Scrolling Text</h3>
      <Input
        placeholder="Enter text"
        onChange={(e) => setTxt(e.target.value)}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginBlock: "10px",
        }}
      >
        <Button
          style={{ background: "orange", color: "white", border: "none" }}
          onClick={() => {
            if (txt) {
              submit();
            } else {
              message.error("Please Enter value");
            }
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ScrollingText;
