import { Button, DatePicker, Input, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../componets/pagination/Pagination.jsx";
import {
  aprrovedWithdrawalTodos,
  paidMoneyTodos,
} from "../../redux/selector/Selector";
import { PaidMoneyAction } from "../../redux/action/paidMoneyAction/PaidMoneyAction.js";
import { accountDetailForWithdrawal } from "../../redux/action/accountDetailForWithdrawal";
import ModalComponent from "../modal/ModalComponent";
import { Link } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";

const PaidMoneyComponent = () => {
  const [serchvalue, setSerchvalue] = useState("");
  const [date, setDate] = useState(dayjs());
  const [range, setRange] = useState(50);
  const [fromdate, setFromdate] = useState(dayjs());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalValue, setModalValue] = useState(0);
  const [payload, setPayload] = useState({
    index: 1,
    totalPages: 0,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      PaidMoneyAction({
        page: payload.index,
        date: moment(date).format("MM/DD/YYYY"),
        fromdate: moment(fromdate).format("MM/DD/YYYY"),
        range: range,
        serchvalue: serchvalue,
      })
    );
  }, [payload?.index, date, fromdate, range, serchvalue]);
  const getaccountDetailForWithdrawal = (data) => {
    dispatch(accountDetailForWithdrawal(data));
  };

  const todos = useSelector(paidMoneyTodos);
  const data = todos?.data?.data;

  useEffect(() => {
    setPayload((prev) => {
      return {
        ...prev,
        totalPages: todos?.data?.Totoal_Pages,
      };
    });
  }, [todos?.data?.Totoal_Pages]);
  const dataSource = data?.map((res, index) => {
    return {
      key: res.user + res.Amount + res.id,
      Sno: index + 1,
      RequestDate: res.RequestDate,
      RequestTime: res.RequestTime,
      user: (
        <Link to={`/user-deatil/${res.User}`} style={{ color: "black" }}>
          {res.User}
        </Link>
      ),

      Username: (
        <Link to={`/user-deatil/${res.User}`} style={{ color: "black" }}>
          {res.UserName}
        </Link>
      ),
      Reqid: res.Reqid,
      UTRNumber: res.UTRNumber,
      AccountUPI: (
        <Button
          style={{ background: "green", border: "none", color: "white" }}
          onClick={() => {
            setModalValue(0);
            showModal();
            getaccountDetailForWithdrawal({ id: res.AccountUPI, type: "3" });
          }}
        >
          Check
        </Button>
      ),
      FromAccount: (
        <Button
          style={{ background: "green", border: "none", color: "white" }}
          onClick={() => {
            setModalValue(1);
            showModal();
            getaccountDetailForWithdrawal({
              id: res.FromAccount,
              type: res.type,
            });
          }}
        >
          Check
        </Button>
      ),
      date: res.date,
      time: res.time,
      Amount: res.Amount,
    };
  });
  const showModal = () => {
    setIsModalOpen(true);
  };

  const approvedData = useSelector(aprrovedWithdrawalTodos);
  const getApprovedData = approvedData?.data?.data;
  const searchHandleChange = (e) => {
    setSerchvalue(e.target.value);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "Sno",
      key: "Sno",
    },
    {
      title: "Request Date",
      dataIndex: "RequestDate",
      key: "RequestDate",
      // filteredValue: [serchvalue],
      // onFilter: (value, record) => {
      //   return (
      //     String(record?.Username?.props?.children)
      //       .toLowerCase()
      //       .includes(value.toLowerCase()) ||
      //     String(record?.RequestDate)
      //       .toLowerCase()
      //       .includes(value.toLowerCase()) ||
      //     String(record?.user?.props?.children)
      //       .toLowerCase()
      //       .includes(value.toLowerCase())
      //   );
      // },
    },
    {
      title: "Request Time",
      dataIndex: "RequestTime",
      key: "RequestTime",
    },

    {
      title: "User ID",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "User Name",
      dataIndex: "Username",
      key: "Username",
    },
    {
      title: "Req Id",
      dataIndex: "Reqid",
      key: "Reqid",
    },
    {
      title: "UTR Number",
      dataIndex: "UTRNumber",
      key: "UTRNumber",
    },

    {
      title: "Approve Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "TO Ac / UPI",
      dataIndex: "AccountUPI",
      key: "AccountUPI",
    },
    {
      title: "From Ac / UPI",
      dataIndex: "FromAccount",
      key: "FromAccount",
    },
    {
      title: "Approve Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
  ];

  const onChange2 = (date, dateString) => {
    setFromdate(dateString);
  };
  const onChange = (date, dateString) => {
    setDate(dateString);
  };
  const handleChange = (value) => {
    setRange(value);
  };
  return (
    <div>
      <ModalComponent
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        compo={
          modalValue == 0 ? (
            <>
              <p style={{ margin: "0", fontWeight: "800", fontSize: "24px" }}>
                Transfer To
              </p>
              <ul style={{ margin: "0", padding: "0" }}>
                <li style={{ listStyle: "none" }}>
                  BankHolderName :
                  <span style={{ paddingLeft: "20px" }}>
                    {getApprovedData?.BankHolderName}
                  </span>
                </li>
                {getApprovedData?.UPIID == 0 ? (
                  <>
                    <li style={{ listStyle: "none" }}>
                      BankName:
                      <span style={{ paddingLeft: "20px" }}>
                        {getApprovedData?.BankName}
                      </span>
                    </li>
                    <li style={{ listStyle: "none" }}>
                      AccountNumber:
                      <span style={{ paddingLeft: "20px" }}>
                        {getApprovedData?.AccountNumber}
                      </span>
                    </li>
                    <li style={{ listStyle: "none" }}>
                      IFSC:
                      <span style={{ paddingLeft: "20px" }}>
                        {getApprovedData?.IFSC}
                      </span>
                    </li>
                  </>
                ) : (
                  <li style={{ listStyle: "none" }}>
                    UPI ID:
                    <span style={{ paddingLeft: "20px" }}>
                      {getApprovedData?.UPIID}
                    </span>
                  </li>
                )}
              </ul>
            </>
          ) : (
            <>
              <p style={{ margin: "0", fontWeight: "800", fontSize: "24px" }}>
                Transfer From
              </p>
              {getApprovedData?.BankName == 0 ? (
                <ul style={{ margin: "0", padding: "0" }}>
                  <li style={{ listStyle: "none" }}>
                    UPI Holder Name:
                    <span style={{ paddingLeft: "20px" }}>
                      {getApprovedData?.UpiHolderNAme}
                    </span>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    UPI ID:
                    <span style={{ paddingLeft: "20px" }}>
                      {getApprovedData?.UpiID}
                    </span>
                  </li>
                </ul>
              ) : (
                <ul style={{ margin: "0", padding: "0" }}>
                  <li style={{ listStyle: "none" }}>
                    HolderName:
                    <span style={{ paddingLeft: "20px" }}>
                      {getApprovedData?.HolderName}
                    </span>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    BankName:
                    <span style={{ paddingLeft: "20px" }}>
                      {getApprovedData?.BankName}
                    </span>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    AccountNo:
                    <span style={{ paddingLeft: "20px" }}>
                      {getApprovedData?.AccountNo}
                    </span>
                  </li>

                  <li style={{ listStyle: "none" }}>
                    IFSC:
                    <span style={{ paddingLeft: "20px" }}>
                      {getApprovedData?.IFSCCode}
                    </span>
                  </li>
                </ul>
              )}
            </>
          )
        }
      />
      <div
        className="search-all-user"
        style={{
          margin: "10px",
          display: "flex",
          alignItems: "end",
          gap: "10px",
        }}
      >
        <div>
          <h3>Search User</h3>
          <Input
            placeholder="search...."
            onChange={searchHandleChange}
            style={{ width: "200px" }}
            value={serchvalue}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <label>From</label>
          <DatePicker onChange={onChange2} />
          <label>TO</label>
          <DatePicker onChange={onChange} />
          <Select
            defaultValue="50"
            style={{
              width: 120,
            }}
            onChange={handleChange}
            // allowClear
            options={[
              {
                value: "50",
                label: "50",
              },
              {
                value: "100",
                label: "100",
              },
              {
                value: "300",
                label: "300",
              },
              {
                value: "400",
                label: "400",
              },
              {
                value: "500",
                label: "500",
              },
            ]}
          />
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        summary={(pageData) => {
          let totalBorrow = 0;

          pageData?.forEach(({ Amount }) => {
            totalBorrow += Number(Amount);
          });
          return (
            <>
              <Table.Summary.Row style={{ background: "rgb(211, 211, 211)" }}>
                <Table.Summary.Cell index={0}>Total Paid</Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>

                <Table.Summary.Cell index={3}>{totalBorrow}</Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <Pagination paginationData={payload} setPaginationData={setPayload} />
    </div>
  );
};
export default PaidMoneyComponent;
