import React, { useEffect, useState } from "react";
import { BiRupee } from "react-icons/bi";
import { AiOutlineEdit } from "react-icons/ai";
import moment from "moment";
import { Button, Input, Modal } from "antd";
import { updateNumberAction } from "../../redux/action/updateNumber";
import { useDispatch } from "react-redux";
const Card = ({ curElm, findNumber, no, result, defaultDate }) => {
  //   console.log(userGameHistoryData);
  //   if (curElm === userGameHistoryData[curElm])
  const dateChecker = moment().format("DD-MM-YYYY");
  const chekDate = dateChecker === moment(defaultDate).format("DD-MM-YYYY");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState("");
  const [num, setNum] = useState("");
  const showModal = (id) => {
    setId(id);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const numberUpadteNumberData = {
    id,
    num,
  };
  const dispatch = useDispatch();
  const updateNumber = () => {
    dispatch(updateNumberAction(numberUpadteNumberData));
  };
  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={600}
        footer={null}
      >
        <p>Manupulate Number</p>
        <Input value={num} onChange={(e) => setNum(e.target.value)} />
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
        >
          <Button onClick={() => updateNumber()}>Submit</Button>
        </div>
      </Modal>
      <div
        className="user-no-card"
        style={{
          background:
            no == 0
              ? result?.Result == curElm
                ? "green"
                : findNumber?.Amount
                ? "rgb(66, 165, 245)"
                : ""
              : result?.Result == curElm + no
              ? "green"
              : findNumber?.Amount
              ? "rgb(66, 165, 245)"
              : "",
          color: findNumber?.Amount ? "white" : "",
        }}
      >
        <span style={{ fontWeight: "bolder" }}>
          {findNumber?.Amount ? <BiRupee /> : ""}
          {findNumber?.Amount}
        </span>
        {no == 0 ? (
          <span> {curElm < 10 ? no + curElm : curElm} </span>
        ) : (
          <span> {curElm + no} </span>
        )}

        <span> {findNumber?.time}</span>
        {findNumber?.Amount && (
          <div
            className="edit"
            onClick={() => showModal(findNumber?.id)}
            style={{ cursor: "pointer" }}
          >
            <AiOutlineEdit />
          </div>
        )}
      </div>
    </>
  );
};

export default Card;
