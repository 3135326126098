import React, { useState } from "react";
import { Avatar, Button, Modal, Tabs } from "antd";
import { Link, useParams } from "react-router-dom";
import { MdCasino } from "react-icons/md";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { userProfileAction } from "../../redux/action/userProfile";
import { useEffect } from "react";
import {
  gamePalyedHistoryTodos,
  userProfileTodos,
  userWinningHistoryTodos,
} from "../../redux/selector/Selector";
import { BiRupee } from "react-icons/bi";
////styles
import "./styles.scss";
import ReferHistory from "../../componets/referHistoryComponent";
import CollapseDetail from "./collapseDetail";
import { gamePalyedHistory } from "../../redux/action/gamePlayedHistory";
import { userWinningHistoryAction } from "../../redux/action/userWinningHistory";
import WalletHistory from "./WalletHistory";
import GameHistory from "./GameHistory";
import WalletDataTable from "./WalletDataTable";
const UserProfile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userProfileAction(user));
    dispatch(gamePalyedHistory(user));
    dispatch(userWinningHistoryAction(user));
  }, [user]);
  const userWinnigHistory = useSelector(userWinningHistoryTodos);
  const winningData = userWinnigHistory?.data?.dataWinning;
  const winningGameName = userWinnigHistory?.data?.GameName;
  const userData = useSelector(userProfileTodos);
  const Rewardrecived = userData?.data?.Rewardrecived;
  let RewardSend = userData?.data;
  const userProfileData = userData?.data?.data;

  const showModal = (id) => {
    setModalOpenvalue(id.id);
    setStatus(id.status);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const gamePlayedHistoryData = useSelector(gamePalyedHistoryTodos);
  const gameData = gamePlayedHistoryData?.gameDeatilData?.data?.data;
  const GameName = gamePlayedHistoryData?.gameDeatilData?.data?.GameName;
  const items = [
    {
      key: "1",
      label: "Game History",
      children: <GameHistory no={userProfileData?.phone} />,
    },
    {
      key: "2",
      label: "Wallet History",
      children: <WalletHistory />,
    },
  ];
  const [modalOpenvalue, setModalOpenvalue] = useState(0);
  const [status, setStatus] = useState(0);
  const modalObj = {
    0: <ReferHistory data={RewardSend?.RewardSend} />,
    1: <WalletDataTable status={status} user={user} />,
  };
  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={600}
        footer={null}
      >
        {modalObj[modalOpenvalue]}
      </Modal>
      <div className="user-profile-container">
        <div className="user-header-data">
          <p
            className="button-17"
            style={{
              background: "rgb(197, 103, 234)",
              color: "white",
              whiteSpace: "nowrap",
            }}
            onClick={() => showModal({ id: 1, status: 1 })}
          >
            Recharge Amount : {RewardSend?.RechargeAmount}
            <BiRupee />
          </p>
          <p
            className="button-17"
            style={{
              background: "rgb(103, 109, 234)",
              color: "white",
              whiteSpace: "nowrap",
            }}
            onClick={() => showModal({ id: 1, status: 2 })}
          >
            Reward Amount : {RewardSend?.RewardAmount}
            <BiRupee />
          </p>
          <p
            className="button-17"
            style={{
              background: "rgb(118, 232, 230)",
              color: "black",
              whiteSpace: "nowrap",
            }}
            onClick={() => showModal({ id: 1, status: 3 })}
          >
            Winning Amount : {RewardSend?.WinningAmount}
            <BiRupee />
          </p>
          <p
            className="button-17"
            style={{
              background: "#94e894",
              color: "black",
              whiteSpace: "nowrap",
            }}
          >
            Total Wallet :{userProfileData?.WalletAmount}
            <BiRupee />
          </p>
        </div>
        <div className="top-pfofile-section">
          <div className="top-left-col">
            <Avatar
              style={{
                backgroundColor: "#87d068",
                color: "#f56a00",
                textTransform: "capitalize",
                fontSize: "34px",
              }}
              size={124}
            >
              {userProfileData?.name && Array.from(userProfileData?.name)[0]}
            </Avatar>
            <Button
              className="button-17"
              style={{ width: "180px" }}
              onClick={() =>
                RewardSend === null ? "" : showModal({ id: 0, status: 0 })
              }
            >
              Refer:
              {RewardSend?.RewardSend === null ? (
                <span style={{ fontWeight: "900", paddingLeft: "6px" }}>0</span>
              ) : (
                ""
              )}
            </Button>
          </div>
          <div className="top-right-col">
            <p className="name">
              <p>{userProfileData?.name}</p>
            </p>
            <p className="number">
              <p>{userProfileData?.phone}</p>
              <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                Joining Date: {userProfileData?.date}
              </p>
            </p>

            <p className="player">
              {userProfileData?.usertype === "2" ? (
                <>
                  Player
                  <span>
                    <MdCasino />
                  </span>
                </>
              ) : (
                "KahyiWall"
              )}
            </p>
            <p className="refrence">
              <p>
                {Rewardrecived === null ? (
                  <div className="">User From Link </div>
                ) : (
                  <>
                    Refrence <AiOutlineArrowLeft className="left-arrow" />
                    <Link
                      to={`/user-deatil/${Rewardrecived?.sender}`}
                      style={{ color: "black" }}
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {Rewardrecived?.sender}
                        <p
                          style={{
                            paddingLeft: "10px",
                            fontWeight: "800",
                            margin: "0px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          ({RewardSend?.RewardrecivedName})
                        </p>
                      </span>
                    </Link>
                    <div
                      className="blink"
                      style={{
                        fontWeight: "900",
                        color: "green",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      +50 <BiRupee />
                    </div>
                  </>
                )}
              </p>
            </p>
          </div>
        </div>
        <></>
        <div className="tabs-container">
          <Tabs defaultActiveKey="1" items={items} type="card" />
        </div>
      </div>
    </>
  );
};

export default UserProfile;
