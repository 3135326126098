export const columns = [
  {
    title: "Game_Name",
    dataIndex: "Game_Name",
    key: "Game_Name",
  },
  {
    title: "game",
    dataIndex: "game",
    key: "game",
  },
  {
    title: "number",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    key: "Amount",
  },
];
