import React, { useState } from "react";

// Styles
import "./styles.scss";
import { getUserByBet } from "../../redux/action/getUserByBet/GetUserByBet";
import { useDispatch, useSelector } from "react-redux";
import { selectUSerBetDetailTodos } from "../../redux/selector/Selector";
import { useParams } from "react-router-dom";
import ModalComponent from "../modal/ModalComponent";
import UserBetDetail from "../userBetDetail/UserBetDetail";
import { Select, Tooltip } from "antd";
import { FaRupeeSign } from "react-icons/fa";
import { useEffect } from "react";

const OddsButton = ({
  data,
  top,
  keys,
  tableDataLength,
  Total,
  sumData,
  date,
  getRuppesValue,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [particularData, setParticularData] = useState();
  const [top10Obj, setTop10Obj] = useState([{}]);
  const [gameValue, setgameValue] = useState("");
  const [top10Lowest, setTop10Lowest] = useState([]);
  const { id } = useParams();
  const dataValue = data?.map(({ rupees }) => {
    return rupees;
  });

  useEffect(() => {
    if (dataValue) {
      function sortBy(field) {
        return function (a, b) {
          return b[field] - a[field];
        };
      }
      let myArr = data;
      let sortAyay = myArr.sort(sortBy("rupees")).slice(0, top);
      setTop10Obj(sortAyay);
      function sortByLowest(field) {
        return function (a, b) {
          return a[field] - b[field];
        };
      }
      let myArrLowest = data;
      let sortAyayLowest = myArrLowest
        .sort(sortByLowest("rupees"))
        .slice(0, top);
      setTop10Lowest(sortAyayLowest);
      // console.log(sortAyay, "adfkj");
    }
  }, [date, data]);

  const dispatch = useDispatch();

  const getDataFun = (sendData) => {
    const data = {
      date: date,
      number: sendData.no,
      game: id,
      gn: sendData.game,
    };
    dispatch(getUserByBet(data));
  };
  const bannerData = useSelector(selectUSerBetDetailTodos);
  const baneer = bannerData?.getDataOnButton?.data || [];
  // {}
  const showModal = (userDat) => {
    setIsModalOpen(true);
    getDataFun(userDat);
    setParticularData(userDat);
  };

  let sum = Number("");
  for (let key in data) {
    sum += Number(data[key]?.rupees);
    Total += sum;
  }
  const options = [
    {
      value: "jodi",
      label: "jodi",
    },
    {
      value: "cross",
      label: "cross",
    },
  ];
  const handleChange = (value) => {
    setgameValue(value);
    showModal(value);
  };

  return (
    <>
      <ModalComponent
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        compo={
          <UserBetDetail
            data={baneer?.game}
            Total={particularData}
            sumData={sumData}
            number={particularData?.no}
            date={date}
          />
        }
        Total={particularData}
      />
      <table className="table-component">
        <tr>
          {Array(tableDataLength)
            .fill()
            .map((_, curElm) => {
              const found = data?.find((obj) => {
                return (
                  obj?.number == curElm ||
                  obj?.number == `${curElm}A` ||
                  obj?.number == `${curElm}B`
                );
              });

              if (found) {
                let findDataHighest = top10Obj.find((curElm) => {
                  return (
                    curElm.number == found.number &&
                    curElm.rupees == found.rupees
                  );
                });
                let findDataLowest = top10Lowest.find((curElm) => {
                  return (
                    curElm.number == found.number &&
                    curElm.rupees == found.rupees
                  );
                });
                const innerItemBlock = (
                  <td
                    style={{
                      background:
                        getRuppesValue == found.number
                          ? "green"
                          : findDataHighest
                          ? "red"
                          : findDataLowest
                          ? "#42a5f5"
                          : "gray",
                      color:
                        getRuppesValue == found.number
                          ? "white"
                          : findDataHighest
                          ? "white"
                          : findDataLowest
                          ? "white"
                          : "white",
                    }}
                    onClick={() =>
                      keys !== "jodi" &&
                      showModal({
                        no: found?.number,
                        game: keys,
                        rupees: found.rupees,
                      })
                    }
                  >
                    <p>
                      <FaRupeeSign />
                      {found?.rupees}
                    </p>
                    <p>{found?.number}</p>
                  </td>
                );
                if (keys === "jodi")
                  return (
                    <React.Fragment key={found.rupees + found.number}>
                      <Tooltip
                        placement="topRight"
                        title={
                          <Select
                            options={options}
                            defaultValue="select"
                            style={{
                              width: 120,
                            }}
                            onChange={(value) =>
                              handleChange({
                                no: found?.number,
                                rupees: found.rupees,
                                game: value,
                              })
                            }
                          ></Select>
                        }
                      >
                        {innerItemBlock}
                      </Tooltip>
                    </React.Fragment>
                  );
                else {
                  return innerItemBlock;
                }
              } else {
                return (
                  <td
                    style={{
                      background:
                        getRuppesValue == curElm ||
                        getRuppesValue == `${curElm}A` ||
                        getRuppesValue == `${curElm}B`
                          ? "green"
                          : "",
                      color:
                        getRuppesValue == curElm ||
                        getRuppesValue == `${curElm}A` ||
                        getRuppesValue == `${curElm}B`
                          ? "white"
                          : "",
                    }}
                  >
                    <p>-</p>
                    <p>{curElm}</p>
                  </td>
                );
              }
            })}
          <tfoot className="table-footer">
            <tr>
              <td>sum</td>
              <td>{sum}</td>
            </tr>
          </tfoot>
        </tr>
      </table>
    </>
  );
};

export default OddsButton;
