import React from "react";
import SignInForm from "../../componets/form/signInForm/SignInForm";

const SignIn = () => {
  return (
    <div>
      <SignInForm />
    </div>
  );
};

export default SignIn;
