import { Button, DatePicker, Input, Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { withdrawalReducer } from "../../redux/reducers/withdrawalReducer/WithdrawalReducer";
import { withdrawalAction } from "../../redux/action/withdrawal/WithdrawalAction";
import {
  adminAccountsTodos,
  withdrawalTodos,
} from "../../redux/selector/Selector";
import { CSVLink } from "react-csv";
import ModalComponent from "../../componets/modal/ModalComponent";
import { useState } from "react";
// import UpdateResultComponent from "../../componets/updateResultComponent/UpdateResultComponent";
import AccountDetail from "../../componets/accountDetail/AccountDetail";
import { approveWithdrawalAction } from "../../redux/action/approveWithdrawalRequest/ApproveWithdrawalRequest";
import ApproveModal from "../../componets/approveModal";
import { adminAccounts } from "../../redux/action/adminAccounts";
import { Link } from "react-router-dom";
import moment from "moment";

const WithdrawalPage = () => {
  const { TextArea } = Input;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);

  const [approveData, setApproveData] = useState({});

  const [accountData, setAccountData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(withdrawalAction());
  }, []);

  const handleClickApi = (data) => {
    dispatch(approveWithdrawalAction(data));
  };
  const changeWithdrawalStatus = () => {
    dispatch(approveWithdrawalAction(approveData));
  };
  const data = useSelector(withdrawalTodos);
  const request = data?.data?.data?.request;
  const account = data?.data?.data?.account;
  console.log(account);

  const showModal = (idKey) => {
    setIsModalOpen(true);
    // setGameId(gameId);
    const found = account.find((obj) => {
      return obj.id == idKey.trim();
    });

    setAccountData(found);
  };
  const showModal2 = (id) => {
    setIsModalOpen2(true);
    setApproveData(id);
  };
  const showModal3 = (id) => {
    setApproveData(id);
    setIsModalOpen3(true);
  };
  const adminAccountsDetail = useSelector(adminAccountsTodos);
  const getAdminAccountsData = adminAccountsDetail?.data?.data;
  const adminAccountsAction = () => {
    dispatch(adminAccounts());
  };

  const [serchvalue, setSerchvalue] = useState("");
  const dataSource = request?.map((res, index) => {
    return {
      SNo: index + 1,
      Date: res.Date,
      key: res.Date + res.Time + res.User,
      Time: res.time,
      User: (
        <Link to={`/user-deatil/${res.User}`} style={{ color: "black" }}>
          {res.User}
        </Link>
      ),
      UserName: (
        <Link to={`/user-deatil/${res.User}`} style={{ color: "black" }}>
          {res.UserName}
        </Link>
      ),
      RequestID: res.RequestID,
      Status: res.Status == 0 ? <p style={{ color: "orange" }}>Pending</p> : "",
      amount: res.Amount,
      Action: (
        <>
          <Button onClick={() => showModal(res.AccountUpi)}>View</Button>
          <Button
            onClick={() =>
              showModal3({
                id: res.id,
                amount: res.Amount,
                user: res.User,
                account: res.AccountUpi,
                ab: 2,
              })
            }
            style={{ background: "red", color: "white", marginLeft: "10px" }}
          >
            Reject
          </Button>

          <Button
            style={{ background: "green", color: "white", marginLeft: "10px" }}
            onClick={() => {
              showModal2({
                id: res.id,
                amount: res.Amount,
                user: res.User,
                account: res.AccountUpi,
                ab: 1,
              });
              userFindData(res.RequestID);
              adminAccountsAction();
            }}
          >
            Approve
          </Button>
        </>
      ),
    };
  });
  const [uSerParticularData, setUSerParticularData] = useState([]);
  const userFindData = (RequestID) => {
    const data = request.find((curElm) => {
      return curElm.RequestID == RequestID;
    });
    setUSerParticularData(data);
  };

  const searchHandleChange = (e) => {
    setSerchvalue(e.target.value);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "SNo",
      key: "SNo",
      filteredValue: [serchvalue],
      onFilter: (value, record) => {
        return (
          String(record?.User?.props?.children)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record?.Date).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.UserName?.props?.children)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },
    {
      title: "RequestID",
      dataIndex: "RequestID",
      key: "RequestID",
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Time",
      dataIndex: "Time",
      key: "Time",
    },
    {
      title: "User ID",
      dataIndex: "User",
      key: "User",
    },
    {
      title: "User Name",
      dataIndex: "UserName",
      key: "UserName",
    },

    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
  ];

  // const ExcelExportData = [
  //   {
  //     FirstName: "Arul",
  //     LastName: "prasath",

  //     Employeecode: "001",
  //     MobileNo: "1234567890",
  //     DOB: "01-01-1995",
  //     Address: "Chennai",
  //   },
  //   {
  //     FirstName: "Balu",
  //     LastName: "Subramani",
  //     Employeecode: "002",
  //     MobileNo: "8787987898",
  //     DOB: "02-02-2000",
  //     Address: "Cbe",
  //   },
  // ];
  const onChange = (date, dateString) => {
    const dateFormat = moment(dateString).format("DD/MM/YYYY");
    setSerchvalue(dateFormat);
  };
  return (
    <div>
      <ModalComponent
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        width={"100%"}
        compo={<AccountDetail data={accountData} />}
      />
      <ModalComponent
        isModalOpen={isModalOpen3}
        setIsModalOpen={setIsModalOpen3}
        // width={"100%"}
        compo={
          <div>
            <p>Please Enter Resion</p>
            <TextArea
              rows={4}
              placeholder="Write Resion"
              onChange={(e) =>
                setApproveData((prev) => {
                  return {
                    ...prev,
                    msg: e.target.value,
                  };
                })
              }
            />
            <p>
              <Button
                style={{ background: "orange", color: "white" }}
                onClick={() =>
                  approveData?.msg
                    ? handleClickApi(approveData)
                    : alert("Please Enter Resion")
                }
              >
                Continue
              </Button>
            </p>
          </div>
        }
      />
      <ModalComponent
        isModalOpen={isModalOpen2}
        setIsModalOpen={setIsModalOpen2}
        compo={
          <>
            <ApproveModal
              setApproveData={setApproveData}
              approveData={approveData}
              handleClickApi={changeWithdrawalStatus}
              data={getAdminAccountsData}
              accountData={accountData}
              userParticularData={uSerParticularData}
            />
          </>
        }
      />
      <h1 style={{ paddingLeft: "10px", fontSize: "22px" }}>
        Withdrawal Request
      </h1>
      <div
        className="search-all-user"
        style={{
          margin: "10px",
          display: "flex",
          alignItems: "end",
          gap: "10px",
        }}
      >
        <div>
          <h3>Search User</h3>
          <Input
            placeholder="search...."
            onChange={searchHandleChange}
            style={{ width: "200px" }}
            value={serchvalue}
          />
        </div>
        <DatePicker onChange={onChange} />
      </div>
      {/* <CSVLink
        data={account}
        onClick={() => {
          console.log("clicked");
        }}
      >
        Download me
      </CSVLink> */}
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </div>
  );
};

export default WithdrawalPage;
