import React, { useEffect, useState } from "react";
import { DatePicker, Empty, Select, Table } from "antd";
////styles
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../redux/action/marketAnalysisAction/MarketAnalysisAction";
import {
  selectGameTodos,
  userGameHistoryTodos,
} from "../../redux/selector/Selector";
import { getUserGameHistory } from "../../redux/action/gameHistoryAction";
import dayjs from "dayjs";
import moment from "moment";
import { BiRupee } from "react-icons/bi";
import Card from "./Card";
import Total from "./total";
export let getUserGameHistoryRef;
const GameHistory = ({ no, setTotal }) => {
  const [gameId, setGameId] = useState("2");
  const [defaultDate, setdefaultDate] = useState(dayjs());
  const onChange = (date, dateString) => {
    setdefaultDate(dateString);
  };
  const handleChange = (value) => {
    setGameId(value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getData());
  }, []);
  const bannerData = useSelector(selectGameTodos);
  const getGameData = bannerData?.data?.data;
  const options = getGameData?.map((curElm) => {
    return {
      value: curElm?.id,
      label: (
        <span>
          {curElm?.name} ({curElm.id})
        </span>
      ),
    };
  });
  const gameUserHistoryPayload = {
    id: no,
    date: moment(defaultDate).format("MM/DD/YYYY"),
    gid: gameId,
  };
  useEffect(() => {
    dispatch(getUserGameHistory(gameUserHistoryPayload));
  }, [gameId, defaultDate, no]);
  getUserGameHistoryRef = getUserGameHistory;

  const getUserGameHistoryData = useSelector(userGameHistoryTodos);
  const userGameHistoryData = getUserGameHistoryData?.data?.data?.data?.jodi;
  const result = getUserGameHistoryData?.data?.data?.data?.result;
  const userGameHistoryDataAndar =
    getUserGameHistoryData?.data?.data?.data?.andar;
  const userGameHistoryDataBhar =
    getUserGameHistoryData?.data?.data?.data?.bahar;
  const tableDataLength = 100;
  let total = 0;
  let totalAndar = 0;
  let totalBahar = 0;

  userGameHistoryData?.map((curElm, index) => {
    total += Number(curElm?.Amount);

    // console.log(curElm?.Amount);
  });
  userGameHistoryDataAndar?.map((curElm, index) => {
    totalAndar += Number(curElm?.Amount);
    // console.log(curElm?.Amount);
  });
  userGameHistoryDataBhar?.map((curElm, index) => {
    totalBahar += Number(curElm?.Amount);

    // console.log(curElm?.Amount);
  });
  // console.log(total, "total");

  // let jodiTotal = result?.length && result[0] * 95;
  // let andarTotal = result?.length && result[1] * 9.5;
  // let baharTotal = result?.length && result[2] * 9.5;
  // console.log(jodiTotal + andarTotal + baharTotal);
  const findjodiNumber = userGameHistoryData?.find((curElm) => {
    return curElm?.number == result[0]?.Result;
  });
  const findAndarNumber = userGameHistoryDataAndar?.find((curElm) => {
    return curElm?.number == result[1]?.Result;
  });
  const findbahrNumber = userGameHistoryDataBhar?.find((curElm) => {
    return curElm?.number == result[2]?.Result;
  });
  const profiteloss =
    (findjodiNumber?.Amount ? Number(findjodiNumber?.Amount) : 0) * 95 +
    (findAndarNumber?.Amount ? Number(findAndarNumber?.Amount) : 0) * 9.5 +
    (findbahrNumber?.Amount ? Number(findbahrNumber?.Amount) : 0) * 9.5;

  let totalRef = profiteloss - (totalBahar + total + totalAndar);
  const dateCheck = dayjs().format("DD-MM-YYYY");
  console.log(dateCheck, "n");
  // let date1 = new Date(d1).getTime();
  // let date2 = new Date(d2).getTime();

  return (
    <div>
      <button
        className="button-17"
        style={{
          marginBottom: "20px",
          background: totalRef >= 0 ? "green" : "Red",
        }}
      >
        {totalRef ? (
          totalRef >= 0 ? (
            <p
              style={{
                color: "white",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              <BiRupee /> {totalRef} Profite
            </p>
          ) : (
            <p
              style={{
                color: "white",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              <BiRupee />
              {totalRef} Loss
            </p>
          )
        ) : (
          0
        )}
      </button>
      <div className="game-history-filter-container">
        <Select
          defaultValue="Disawar"
          style={{
            width: 200,
          }}
          onChange={handleChange}
          options={options}
        />
        <DatePicker onChange={onChange} />
        <p>{moment(defaultDate).format("MMMM Do YYYY")}</p>
      </div>
      <div style={{ padding: "16px 16px", overflow: "hidden" }}>
        <div
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "purple",
            color: "white",
            fontSize: "22px",
            fontWeight: "bolder",
          }}
        >
          Jodi
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {Array(tableDataLength)
            .fill()
            .map((_, curElm) => {
              const findNumber = userGameHistoryData?.find((prev) => {
                return prev?.number == curElm;
              });

              return (
                <>
                  <Card
                    curElm={curElm}
                    findNumber={findNumber}
                    no="0"
                    result={result?.length && result[0]}
                    defaultDate={defaultDate}
                  />
                </>
              );
            })}
        </div>
        <Total total={total} name="Sum" clr="#501d9c" />

        <div
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "purple",
            color: "white",
            fontSize: "22px",
            fontWeight: "bolder",
            marginTop: "8px",
          }}
        >
          Andar
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {Array(10)
            .fill()
            .map((_, curElm) => {
              const findNumber = userGameHistoryDataAndar?.find((prev) => {
                return prev?.number == curElm + "A";
              });
              return (
                <>
                  <Card
                    curElm={curElm}
                    findNumber={findNumber}
                    no="A"
                    result={result?.length && result[1]}
                    defaultDate={defaultDate}
                  />
                </>
              );
            })}
        </div>
        <Total total={totalAndar} name="Sum" clr="#501d9c" />
        <div
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "purple",
            color: "white",
            fontSize: "22px",
            fontWeight: "bolder",
            marginTop: "8px",
          }}
        >
          Bahar
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {Array(10)
            .fill()
            .map((_, curElm) => {
              const findNumber = userGameHistoryDataBhar?.find((prev) => {
                return prev?.number == curElm + "B";
              });
              return (
                <>
                  <Card
                    curElm={curElm}
                    findNumber={findNumber}
                    no="B"
                    result={result?.length && result[2]}
                    defaultDate={defaultDate}
                  />
                </>
              );
            })}
        </div>

        <Total total={totalBahar} name="Sum" clr="#501d9c" />
        <Total
          total={totalBahar + total + totalAndar}
          name="Game Played"
          clr="rgb(29 109 53)"
        />
        <Total total={profiteloss} name="User Winning" clr="rgb(50 151 197)" />
      </div>
    </div>
  );
};

export default GameHistory;
