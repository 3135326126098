import React from "react";

const Total = ({ total, name, clr }) => {
  return (
    <div
      className="total-footer"
      style={{
        display: "flex",
        alignItems: "center",
        padding: "2px",
        justifyContent: "space-between",
        background: clr,
        color: "white",
        paddingInline: "10px",
      }}
    >
      <p style={{ fontWeight: "bolder", fontSize: "22px", margin: "0%" }}>
        {name}
      </p>
      <p style={{ fontWeight: "bolder", fontSize: "22px", margin: "0%" }}>
        {total}/-
      </p>
    </div>
  );
};

export default Total;
