import axios from "axios";
import { ActionType } from "../../actionType/ActionType";
import { message } from "antd";
import { signIn } from "../../../routes/pagesRoutes";
import { getUserGameHistory } from "../gameHistoryAction";

export const updateNumberAction = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionType.REQUEST_PENDING_UPDATE_NUMBER });
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}/AdminHandling/menuplatenumberbyDashboarGamesaccess.php`,
        id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (data) {
        if (data?.data?.status == 200) {
          message.success(data?.data?.message);
        }
        dispatch(getUserGameHistory());
        dispatch({
          type: ActionType.REQUEST_SUCCESS_UPDATE_NUMBER,
          payload: data.data,
        });
      }
    } catch (response) {
      if (response?.response?.status === 401) {
        localStorage.clear();
        window.location.replace(signIn);
      } else if (response?.response?.data?.message) {
        message.error(response?.response?.data?.message);
      }
      dispatch({
        type: ActionType.REQUEST_ERROR_UPDATE_NUMBER,
        payload: response,
      });
    }
  };
};
