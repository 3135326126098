import { Button, Input, Switch } from "antd";
import React, { useEffect, useState } from "react";

const ApproveModal = ({
  handleClickApi,
  data,
  userParticularData,
  accountData,
  setApproveData,
}) => {
  const [checkedValue, setCheckedValue] = useState({});
  const [sendData, setsendData] = useState({
    reqdate: userParticularData?.Date,
    reqtime: userParticularData?.time,
    reqid: userParticularData?.RequestID,
    utr: "",
    from: checkedValue?.value,
    type: checkedValue?.type,
  });
  useEffect(() => {
    setApproveData((prev) => {
      return {
        ...prev,
        ...sendData,
      };
    });
  }, [sendData]);
  if (data) {
    const accountsDetail = data?.AccountDetails;
    const upiDetail = data?.UpiDetails;

    const onChange = (checked) => {
    };

 
    return (
      <div>
        <p
          style={{
            display: "flex",
            fontWeight: "800",
            fontSize: "27px",
            margin: "0px",
          }}
        >
          Transfer To:
        </p>
        <ul style={{ margin: "0px", padding: 0 }}>
          <li style={{ listStyle: "none" }}>
            Name:{" "}
            <span style={{ paddingLeft: "10px" }}>
              {userParticularData?.UserName}
            </span>
          </li>

          <li style={{ listStyle: "none" }}>
            RequestID:
            <span style={{ paddingLeft: "10px" }}>
              {userParticularData.RequestID}
            </span>
          </li>
          <li style={{ listStyle: "none" }}>
            Bank Holder Name:
            <span style={{ paddingLeft: "10px" }}>
              {accountData?.BankHolderName}
            </span>
          </li>
          {accountData.UPIID == 0 ? (
            <>
              <li style={{ listStyle: "none" }}>
                BankName:
                <span style={{ paddingLeft: "10px" }}>
                  {accountData?.BankName}
                </span>
              </li>
              <li style={{ listStyle: "none" }}>
                Account Number:
                <span style={{ paddingLeft: "10px" }}>
                  {accountData?.AccountNumber}
                </span>
              </li>
              <li style={{ listStyle: "none" }}>
                IFSC:
                <span style={{ paddingLeft: "10px" }}>{accountData?.IFSC}</span>
              </li>
            </>
          ) : (
            <>
              <li style={{ listStyle: "none" }}>
                UPI ID:
                <span style={{ paddingLeft: "10px" }}>
                  {accountData?.UPIID}
                </span>
              </li>
            </>
          )}
        </ul>
        <div>
          <p
            style={{
              display: "flex",
              fontWeight: "800",
              fontSize: "27px",
              margin: "0px",
            }}
          >
            From:
          </p>
          <p style={{ background: "gray", color: "white", padding: "5px" }}>
            Accounts Detail
          </p>
          <ul style={{ margin: "0", padding: "0" }}>
            {accountsDetail?.map((curELm) => {
              let digits = curELm.AccountNo;
              return (
                <p>
                  {curELm?.HolderName}
                  <span style={{ paddingLeft: "20px" }}>
                    ({`xxxxxx${digits.substr(digits.length - 4)}`})
                  </span>
                  <span>
                    <Switch
                      size="small"
                      checked={
                        checkedValue.type == 1
                          ? checkedValue?.value == curELm.id
                          : false
                      }
                      onChange={(e) => {
                        onChange(e);
                        setsendData((prev) => {
                          return {
                            ...prev,
                            from: curELm.id,
                            type: 1,
                          };
                        });
                        setCheckedValue((prev) => {
                          return {
                            ...prev,
                            value: curELm.id,
                            type: 1,
                          };
                        });
                      }}
                    />
                  </span>
                </p>
              );
            })}
          </ul>
          <p style={{ background: "gray", color: "white", padding: "5px" }}>
            UPI Detail
          </p>

          <ul style={{ margin: "0", padding: "0" }}>
            {upiDetail?.map((curELm) => {
              return (
                <p>
                  {curELm?.UpiHolderNAme}
                  <span style={{ paddingLeft: "20px" }}>({curELm?.UpiID})</span>
                  <span>
                    <Switch
                      size="small"
                      checked={
                        checkedValue.type == 2
                          ? checkedValue?.value == curELm.id
                          : false
                      }
                      onChange={(e) => {
                        onChange(e);
                        setsendData((prev) => {
                          return {
                            ...prev,
                            from: curELm.id,
                            type: 2,
                          };
                        });
                        setCheckedValue((prev) => {
                          return {
                            ...prev,
                            value: curELm.id,
                            type: 2,
                          };
                        });
                      }}
                    />
                  </span>
                </p>
              );
            })}
          </ul>
          <label htmlFor="">Enter UTR NO:</label>
          <Input
            value={sendData.utr}
            onChange={(e) =>
              setsendData((prev) => {
                return {
                  ...prev,
                  utr: e.target.value,
                };
              })
            }
          />
          <p>
            <Button
              style={{ background: "orange", color: "white" }}
              onClick={() =>
                sendData.utr ? handleClickApi() : alert("Please Enter UTR No")
              }
            >
              Continue
            </Button>
          </p>
        </div>
      </div>
    );
  } else {
    return <p>Loading.....</p>;
  }
};

export default ApproveModal;
