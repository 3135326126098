import React from "react";

import UserGameDataTable from "./UserGameDataTable";

const ViewUserGameDataCollapse = () => {
  return (
    <>
      <UserGameDataTable />
    </>
  );
};

export default ViewUserGameDataCollapse;
