import React from "react";
import { Button, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { addBankAccountDetailAction } from "../../redux/action/addBankDetail";
const UpiForm = ({ type }) => {
  const dispatch = useDispatch();
  const onFinish = (values) => {
    const data = { ...values, type: type };
    dispatch(addBankAccountDetailAction(data));
  };
  const onFinishFailed = (errorInfo) => {
  };
  return (
    <div>
      <h2>Add UPI Account</h2>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="UPI Holder Name"
          name="bholder"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="UPI"
          name="upi"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpiForm;
