import React from "react";
import { useEffect } from "react";
import { adminAccounts } from "../../redux/action/adminAccounts";
import { useDispatch, useSelector } from "react-redux";
import { adminAccountsTodos } from "../../redux/selector/Selector";
import { Button, Table } from "antd";
import { columns, columns2 } from "./ColumsTable";
import { paymentAccountStatusChange } from "../../redux/action/paymentAccountStatusChange";
import ModalComponent from "../../componets/modal/ModalComponent";
import { useState } from "react";
import BankForm from "./BankForm";
import UpiForm from "./UpiForm";

const PaymentAccount = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalValue, setModalValue] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminAccounts());
  }, []);
  const data = useSelector(adminAccountsTodos);
  const AccountDetails = data?.data?.data?.AccountDetails;
  const UpiDetails = data?.data?.data?.UpiDetails;

  const onChange = (data) => {
    dispatch(paymentAccountStatusChange(data));
  };
  const dataSource = AccountDetails?.map((curElm, index) => {
    return {
      key: curElm.AccountNo + index + curElm.BankName,
      Sno: index + 1,
      HolderName: curElm.HolderName,
      BankName: curElm.BankName,
      AccountNo: curElm.AccountNo,
      IFSCCode: curElm.IFSCCode,
      Status:
        curElm.Status == 1 ? (
          <p style={{ color: "green", fontWeight: "700" }}> Active</p>
        ) : (
          "Block"
        ),
      Action: (
        <Button
          style={{ background: "red", color: "white", border: "none" }}
          onClick={() => onChange({ type: "1", id: curElm.id, action: "2" })}
        >
          Delete
        </Button>
      ),
    };
  });

  const dataSource2 = UpiDetails?.map((curElm, index) => {
    return {
      key: curElm.AccountNo + index + curElm.BankName,
      Sno: index + 1,
      HolderName: curElm.UpiHolderNAme,
      BankName: curElm.UpiID,

      Status:
        curElm.Status == 1 ? (
          <p style={{ color: "green", fontWeight: "700" }}> Active</p>
        ) : (
          "Block"
        ),
      Action: (
        <Button
          style={{ background: "red", color: "white", border: "none" }}
          onClick={() => onChange({ type: "2", id: curElm.id, action: "2" })}
        >
          Delete
        </Button>
      ),
    };
  });
  const showModal = () => {
    setIsModalOpen(true);
  };
  const formObj = {
    0: <BankForm type={1}/>,
    1: <UpiForm  type={2}/>,
  };
  return (
    <div>
      <ModalComponent
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        compo={formObj[ModalValue]}
      />
      <h3
        style={{
          fontSize: "25px",
          fontWeight: "800",
          paddingLeft: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        All Payment Accounts
        <p
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            paddingRight: "20px",
          }}
        >
          <Button
            className="button-17"
            onClick={() => {
              setModalValue(0);

              showModal();
            }}
          >
            Add Bank Account
          </Button>
          <Button
            className="button-17"
            onClick={() => {
              setModalValue(1);
              showModal();
            }}
          >
            Add UPI Account
          </Button>
        </p>
      </h3>
      <p
        style={{
          width: "100%",
          background: "#77d0f8",
          fontSize: "25px",
          paddingLeft: "10px",
          fontWeight: "800",
        }}
      >
        Bank Accounts
      </p>
      <Table dataSource={dataSource} columns={columns} />;
      <p
        style={{
          width: "100%",
          background: "#77d0f8",
          fontSize: "25px",
          paddingLeft: "10px",
          fontWeight: "800",
        }}
      >
        UPI Accounts
      </p>
      <Table dataSource={dataSource2} columns={columns2} />;
    </div>
  );
};

export default PaymentAccount;
