import React from "react";
import { Button, Form, Input } from "antd";
////styles
import "./styles.scss";
import { useDispatch } from "react-redux";
import { addBankAccountDetailAction } from "../../redux/action/addBankDetail";
const BankForm = ({ type }) => {
  const dispatch = useDispatch();
  const onFinish = (values) => {
    const data = { ...values, type: type };
    dispatch(addBankAccountDetailAction(data));
  };
  const onFinishFailed = (errorInfo) => {
  };

  //   type= 1 for account add 2 for upi add
  // bank add ho tb =

  // :
  // UPiadd ho to=
  // bholder:
  // upi:

  return (
    <div>
      <h2>Add Bank Account</h2>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Bank Name"
          name="bname"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Bank Holder Name"
          name="bholder"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Account No."
          name="account"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="IFSC"
          name="IFSCCode"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BankForm;
