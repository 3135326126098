import React, { useState } from "react";
import AllUserDetail from "../../componets/allUserDetail/AllUserDetail";
import "./styles.scss";
import { DatePicker, Input, Select, Tabs } from "antd";
import PartnerUser from "../../componets/partnerUser";
import moment from "moment";

const AllUser = () => {
  const [totalUser, setTotalUser] = useState("");
  const [serchvalue, setSerchvalue] = useState("");
  const [range, setRange] = useState(50);
  const [totalUserAmount, setToatalUserAmount] = useState("");

  const searchHandleChange = (e) => {
    setSerchvalue(e.target.value);
  };

  const onChange = (key) => {};
  const tabsItem = [
    {
      label: `Candy User`,
      key: 1,
      children: (
        <AllUserDetail
          setTotalUser={setTotalUser}
          serchvalue={serchvalue}
          setToatalUserAmount={setToatalUserAmount}
          totalUserAmount={totalUserAmount}
          range={range}
        />
      ),
    },
    {
      label: `Partner User`,
      key: 2,
      children: <PartnerUser />,
    },
  ];
  // const onChangeDatePicker = (date, dateString) => {
  //   const dateFormat = moment(dateString).format("DD/MM/YYYY");
  //   setSerchvalue(dateFormat);
  // };
  const handleChange = (value) => {
    setRange(value);
  };
  return (
    <div className="alluser-div">
      <div className="heading-all-user">
        <h1>All USER DETAIL</h1>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <p className="button-17" style={{ fontWeight: "bolder" }}>
            Total user:{totalUser}
          </p>
          <p className="button-17" style={{ fontWeight: "bolder" }}>
            Total User Amount:{totalUserAmount}
          </p>
        </div>
      </div>
      <div
        className="search-all-user"
        style={{
          margin: "10px",
          display: "flex",
          alignItems: "end",
          gap: "10px",
        }}
      >
        <div>
          <h3>Search User</h3>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Input
              placeholder="search...."
              onChange={searchHandleChange}
              style={{ width: "200px" }}
              value={serchvalue}
            />
            <label>Select Range</label>
            <Select
              defaultValue="50"
              style={{
                width: 120,
              }}
              onChange={handleChange}
              // allowClear
              options={[
                {
                  value: "50",
                  label: "50",
                },
                {
                  value: "100",
                  label: "100",
                },
                {
                  value: "300",
                  label: "300",
                },
                {
                  value: "400",
                  label: "400",
                },
                {
                  value: "500",
                  label: "500",
                },
              ]}
            />
          </div>
        </div>
        {/* <DatePicker onChange={onChangeDatePicker} /> */}
      </div>
      <Tabs onChange={onChange} type="card" items={tabsItem} />
    </div>
  );
};

export default AllUser;
