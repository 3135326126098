export const columns2 = [
  {
    title: "Sno",
    dataIndex: "Sno",
    key: "Sno",
  },
  {
    title: "UPI Holder Name",
    dataIndex: "HolderName",
    key: "HolderName",
  },
  {
    title: "UPI ID",
    dataIndex: "BankName",
    key: "BankName",
  },

  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "Action",
  },
];

export const columns = [
  {
    title: "Sno",
    dataIndex: "Sno",
    key: "Sno",
  },
  {
    title: "Holder Name",
    dataIndex: "HolderName",
    key: "HolderName",
  },
  {
    title: "Bank Name",
    dataIndex: "BankName",
    key: "BankName",
  },
  {
    title: "Account No.",
    dataIndex: "AccountNo",
    key: "AccountNo",
  },
  {
    title: "IFSC Code",
    dataIndex: "IFSCCode",
    key: "IFSCCode",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "Action",
  },
];
