import React from "react";
import CreateAdminForm from "../../componets/form/createAdminForm/CreateAdminForm";

const CreateAdmin = () => {
  return (
    <>
      <CreateAdminForm />
    </>
  );
};

export default CreateAdmin;
