import React from "react";
import Clock from "react-live-clock";
const ClockComponent = () => {
  return (
    <>
      <p style={{ fontSize: "18px", margin: "0" }}>
        <Clock
          format={`DD-MM-YYYY / h:mm:ss`}
          ticking={true}
          timezone={"kolkata"}
        />
      </p>
    </>
  );
};
export default ClockComponent;
