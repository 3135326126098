import { Collapse, DatePicker, Empty, Table } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allWalletTransaction } from "../../redux/action/allWalletTransaction";
import { useEffect } from "react";
import { allWalletTransactionTodos } from "../../redux/selector/Selector";
import { useParams } from "react-router-dom";
import { BiRupee } from "react-icons/bi";

const WalletHistory = () => {
  const { Panel } = Collapse;
  const [defaultDate, setdefaultDate] = useState(dayjs());
  const [defaultfrom, setdefaultFrom] = useState(dayjs());

  const onChange = (date, dateString) => {
    setdefaultDate(dateString);
  };
  const onChange2 = (date, dateString) => {
    setdefaultFrom(dateString);
  };
  const { user } = useParams();
  const payload = {
    date: moment(defaultDate).format("MM/DD/YYYY"),
    id: user,
    fromdate: moment(defaultfrom).format("MM/DD/YYYY"),
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allWalletTransaction(payload));
  }, [defaultDate, defaultfrom]);
  const walletTransaction = useSelector(allWalletTransactionTodos);
  const walletTransactionData = walletTransaction?.data?.data;

  const sorted = walletTransactionData?.sort((a, b) => {
    const aDate = new Date(a.date + " " + a.Time);
    const bDate = new Date(b.date + " " + b.Time);

    return bDate.getTime() - aDate.getTime();
  });

  const dataSource = sorted?.map((res, index) => {
    return {
      key: res.date + res.amount + res.particular,
      sno: index + 1,

      date: res.date,
      Time: res?.Time,
      particular: <span style={{ fontWeight: "900" }}>{res.Particauler}</span>,
      amount:
        res.status === "1" ? (
          <span
            style={{
              color: "green",
              fontWeight: "900",
              display: "flex",
              alignItems: "center",
            }}
          >
            +{res?.Amount}
            <BiRupee />
          </span>
        ) : (
          <span
            style={{
              color: "red",
              fontWeight: "900",
              display: "flex",
              alignItems: "center",
            }}
          >
            - {res?.Amount}
            <BiRupee />
          </span>
        ),
    };
  });

  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Time",
      dataIndex: "Time",
      key: "Time",
    },

    {
      title: "Particular",
      dataIndex: "particular",
      key: "particular",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];
  return (
    <div>
      <div className="date-container">
        <label>From</label>
        <DatePicker onChange={onChange2} />
        <label htmlFor="">TO</label>
        <DatePicker onChange={onChange} />
      </div>
      {walletTransactionData ? (
        <Table columns={columns} dataSource={dataSource} />
      ) : (
        <Empty />
      )}

      {/* {Object.keys(walletTransactionData).map((key) => {
          walletTransactionData[key].map((curElm, index) => {
            return (
              <>
                <p style={{ color: "black" }}>
                  {curElm["Date"] || curElm["date"]}
                </p>
              </>
            );
          });
        })} */}
    </div>
  );
};

export default WalletHistory;
