import Sider from "antd/es/layout/Sider";
import React, { useState } from "react";
import { Button, Input, Menu, Modal } from "antd";
import { item } from "./Siderdata";
// Styles
import "../styles.scss";
import { useDispatch } from "react-redux";
import { rechargeCaping } from "../../redux/action/rechargeCaping/rechargeCaping";
export let rechargeCapingRef;
const name = localStorage.getItem("userName");
const AppSidebar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState({
    amount1: "",
    amount2: "",
  });
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  rechargeCapingRef = showModal;

  const dataObj = {
    amt: amount.amount1 || amount.amount2,
    user: name,
    status: 0,
  };
  const dispatch = useDispatch();
  const capingApi = (status) => {
    const data = { ...dataObj, ...{ status } };
    dispatch(rechargeCaping(data));
  };
  return (
    <>
      <Modal
        title="Caping"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <p>Enter Recharge Caping Amount</p>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBlock: "20px",
            gap: "10px",
          }}
        >
          <Input
            value={amount.amount1}
            type="number"
            onChange={(e) =>
              setAmount((prev) => {
                return {
                  ...prev,
                  amount2: "",
                  amount1: e.target.value,
                };
              })
            }
          />
          <Button
            disabled={amount.amount1 ? false : true}
            style={{ background: "orange", color: "white", border: "none" }}
            onClick={() => capingApi(1)}
          >
            Submit
          </Button>
        </div>
        <p>Play Amount Caping </p>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBlock: "20px",
            gap: "10px",
          }}
        >
          <Input
            value={amount.amount2}
            type="number"
            onChange={(e) =>
              setAmount((prev) => {
                return {
                  ...prev,
                  amount1: "",
                  amount2: e.target.value,
                };
              })
            }
          />
          <Button
            disabled={amount.amount2 ? false : true}
            style={{ background: "orange", color: "white", border: "none" }}
            onClick={() => capingApi(2)}
          >
            Submit
          </Button>
        </div>
      </Modal>
      <Sider className="app-sidebar">
        <Menu
          // defaultOpenKeys={[""]}
          // defaultSelectedKeys={[""]}
          mode="inline"
          items={item}
        ></Menu>
        {/* <div className="user-name">
          <Avatar size="large" style={{ background: "green", color: "white" }}>
            {name && Array.from(name)[0]}
          </Avatar>
          <h2>{name}</h2>
        </div> */}
      </Sider>
    </>
  );
};

export default AppSidebar;
