import { DatePicker, Input, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rejectedRequestTodos } from "../../redux/selector/Selector";
import { rejectedRequest } from "../../redux/action/rejectedRequest";
import { Link } from "react-router-dom";
import Pagination from "../../componets/pagination/Pagination";
import moment from "moment";
import dayjs from "dayjs";

const RejectedRequest = () => {
  const [serchvalue, setSerchvalue] = useState("");

  const [date, setDate] = useState(dayjs());
  const [range, setRange] = useState(50);
  const [fromdate, setFromdate] = useState(dayjs());
  const [payload, setPayload] = useState({
    index: 1,
    totalPages: 0,
  });
  const data = useSelector(rejectedRequestTodos);
  const upiList = data?.data?.data;

  useEffect(() => {
    setPayload((prev) => {
      return {
        ...prev,
        totalPages: data?.data?.Totoal_Pages,
      };
    });
  }, [data?.data?.Totoal_Pages]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      rejectedRequest({
        page: payload.index,
        date: moment(date).format("MM/DD/YYYY"),
        fromdate: moment(fromdate).format("MM/DD/YYYY"),
        range: range,
        serchvalue: serchvalue,
      })
    );
  }, [payload.index, date, fromdate, range, serchvalue]);

  const dataSource = upiList?.map((curElm, index) => {
    return {
      sno: index + 1,
      Date: curElm.Date,
      time: curElm.time,
      name: (
        <Link to={`/user-deatil/${curElm.User}`} style={{ color: "black" }}>
          {curElm.User}
        </Link>
      ),
      message: curElm.message,
      AccountUpi: "Winning",
      Amount: curElm.Amount,
    };
  });

  const columns = [
    {
      title: "sno",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "User Number",
      dataIndex: "name",
      key: "name",
      // filteredValue: [serchvalue],
      // onFilter: (value, record) => {
      //   return (
      //     String(record?.name?.props?.children)
      //       .toLowerCase()
      //       .includes(value.toLowerCase()) ||
      //     String(record?.Date).toLowerCase().includes(value.toLowerCase())
      //   );
      // },
    },
    {
      title: "message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Refund",
      dataIndex: "AccountUpi",
      key: "AccountUpi",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
  ];
  const searchHandleChange = (e) => {
    setSerchvalue(e.target.value);
  };
  const onChange2 = (date, dateString) => {
    setFromdate(dateString);
  };
  const onChange = (date, dateString) => {
    setDate(dateString);
  };
  const handleChange = (value) => {
    setRange(value);
  };
  return (
    <div className="update-result-container">
      <h1 style={{ paddingLeft: "10px", fontSize: "22px", marginTop: "0px" }}>
        Rejected Request
      </h1>
      <div
        className="search-all-user"
        style={{
          margin: "10px",
          display: "flex",
          alignItems: "end",
          gap: "10px",
        }}
      >
        <div>
          <h3>Search User</h3>
          <Input
            placeholder="search...."
            onChange={searchHandleChange}
            style={{ width: "200px" }}
            value={serchvalue}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <label>From</label>
          <DatePicker onChange={onChange2} />
          <label>TO</label>
          <DatePicker onChange={onChange} />
          <Select
            defaultValue="50"
            style={{
              width: 120,
            }}
            onChange={handleChange}
            // allowClear
            options={[
              {
                value: "50",
                label: "50",
              },
              {
                value: "100",
                label: "100",
              },
              {
                value: "300",
                label: "300",
              },
              {
                value: "400",
                label: "400",
              },
              {
                value: "500",
                label: "500",
              },
            ]}
          />
        </div>
      </div>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
      <Pagination paginationData={payload} setPaginationData={setPayload} />
    </div>
  );
};

export default RejectedRequest;
