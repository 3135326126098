import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alluserDetailAction } from "../../redux/action/allUserDetailAction/AllUserDetailAction";
import { allUserTodos } from "../../redux/selector/Selector";
// import { columns } from "./TableColumn";
///styles
import "./styles.scss";
import { Link } from "react-router-dom";
import Pagination from "../pagination/Pagination";

const AllUserDetail = ({
  setTotalUser,
  serchvalue,
  setToatalUserAmount,
  range,
}) => {
  const [payload, setPayload] = useState({
    index: 1,
    totalPages: 0,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      alluserDetailAction({
        page: payload.index,
        range: range,
        serchvalue: serchvalue,
      })
    );
  }, [payload.index, range, serchvalue]);

  const data = useSelector(allUserTodos);
  const userData = data?.data?.data;
  const toatalUserBalance = data?.data?.HubAmount;
  const pageSizeData = data?.data?.count;
  useEffect(() => {
    setPayload((prev) => {
      return {
        ...prev,
        totalPages: data?.data?.Totoal_Pages,
      };
    });
  }, [data?.data?.Totoal_Pages]);
  console.log(payload, "pa");
  useEffect(() => {
    setToatalUserAmount(toatalUserBalance);
  }, [toatalUserBalance]);

  setTotalUser(pageSizeData);
  const dataSource = userData?.map((curElm, index) => {
    return {
      key: curElm.name + curElm.phone + index + curElm.date,
      sno: index + 1,
      date: curElm.date,
      name: (
        <Link to={`/user-deatil/${curElm?.phone}`} style={{ color: "black" }}>
          {curElm?.name}
        </Link>
      ),
      phone: (
        <Link to={`/user-deatil/${curElm?.phone}`} style={{ color: "black" }}>
          {curElm.phone}
        </Link>
      ),
      WalletAmount: curElm.WalletAmount,
      // Action: (
      //   <div className="action-div">
      //     <Link to={`${view_Transaction}${curElm.phone}`}>
      //       <Button className="button-17">View Transaction</Button>
      //     </Link>
      //     <Link to={`${view_User_Game_Data}${curElm.phone}`}>
      //       <Button className="button-17">View Game Detail</Button>
      //     </Link>
      //   </div>
      // ),
    };
  });

  const getcolumns = [
    {
      key: "1",
      title: "S.no",
      dataIndex: "sno",
      // filteredValue: [serchvalue],
      // onFilter: (value, record) => {
      //   return (
      //     String(record.name.props.children)
      //       .toLowerCase()
      //       .includes(value.toLowerCase()) ||
      //     String(record.date).toLowerCase().includes(value.toLowerCase()) ||
      //     String(record.phone.props.children)
      //       .toLowerCase()
      //       .includes(value.toLowerCase())
      //   );
      // },
    },
    {
      key: "2",
      title: "Joining Date",
      dataIndex: "date",
    },
    {
      key: "3",
      title: "name",
      dataIndex: "name",
    },

    {
      key: "4",
      title: "phone",
      dataIndex: "phone",
    },
    {
      key: "5",
      title: "WalletAmount",
      dataIndex: "WalletAmount",
    },

    // {
    //   title: "Action",
    //   dataIndex: "Action",
    //   key: "Action",
    // },
  ];

  return (
    <div>
      <Table dataSource={dataSource} columns={getcolumns} pagination={false} />
      <Pagination paginationData={payload} setPaginationData={setPayload} />
    </div>
  );
};

export default AllUserDetail;
