import { useEffect, useState } from "react";
import AppRouter from "./AppRouter";
import "./styles/App.scss";
// import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  // const [count, setcount] = useState(false);
  // useEffect(() => {
  //   const timer = setInterval(
  //     () => {
  //       alert("not found");
  //       setcount(true);
  //     },
  //     !count ? 60000 : 600
  //   );
  //   return () => clearInterval(timer);
  // }, []);

  return (
    <div className="admin-app">
      <AppRouter />
    </div>
  );
}

export default App;
